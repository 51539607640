import { getAPIHost } from '@creatorhub/shared/services';
import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth } from '../contexts/Auth';
import { getHttpClient } from '@creatorhub/shared/util';
const normalizeAnnouncementDraft = (item) => {
    const normalized = {
        ...item,
        publishAt: new Date(item.publishAt),
        removeAt: new Date(item.removeAt),
    };
    if (item.submittedAt) {
        normalized.submittedAt = new Date(item.submittedAt);
    }
    return normalized;
};
export const useListOfAnnouncementDraftsQuery = () => {
    const { token } = useAuth();
    return useQuery({
        queryKey: ['api', 'announcements'],
        queryFn: async () => {
            const res = await axios.get(`${getAPIHost()}/api/announcement-drafts`, {
                headers: { authorization: `Bearer ${token.current}` },
            });
            return res.data.data?.map(normalizeAnnouncementDraft);
        },
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
};
export const useAnnouncementDraftQuery = (announcementId) => {
    const { token } = useAuth();
    return useQuery({
        queryKey: ['api', 'announcements', announcementId],
        queryFn: async () => {
            const res = await axios.get(`${getAPIHost()}/api/announcement-drafts/${announcementId}`, {
                headers: { authorization: `Bearer ${token.current}` },
            });
            return normalizeAnnouncementDraft(res.data.data);
        },
    });
};
export const useCreateAnnouncementDraftMutation = () => {
    const { token } = useAuth();
    return useMutation({
        mutationFn: async (variables) => {
            const res = await getHttpClient({
                headers: { authorization: `Bearer ${token.current}` },
            }).post(`${getAPIHost()}/api/announcement-drafts`, variables);
            return res.data.data;
        },
    });
};
export const useUpdateAnnouncementDraftMutation = (announcementId) => {
    const { token } = useAuth();
    return useMutation({
        mutationFn: async (variables) => {
            const res = await getHttpClient({
                headers: { authorization: `Bearer ${token.current}` },
            }).put(`${getAPIHost()}/api/announcement-drafts/${announcementId}`, variables);
            return res.data.data;
        },
    });
};
/**
 * Soft-delete (archive) mutation
 */
export const useDeleteAnnouncementDraftMutation = () => {
    const { token } = useAuth();
    return useMutation({
        mutationFn: async (variables) => {
            const res = await getHttpClient({
                headers: { authorization: `Bearer ${token.current}` },
            }).delete(`${getAPIHost()}/api/announcement-drafts/${variables.announcementId}`);
            return res.data.data;
        },
    });
};
