import * as v from 'superstruct';
import { integer, positive } from '../util';
import { MAX_NAME_LENGTH, MAX_SYMBOL_LENGTH } from './Constants';
export const MetadataSchema = v.type({
    name: v.size(v.string(), 1, MAX_NAME_LENGTH),
    symbol: v.size(v.string(), 0, MAX_SYMBOL_LENGTH),
    description: v.string(),
    seller_fee_basis_points: v.max(v.min(positive(integer(v.number())), 0), 10000),
    image: v.string(),
    animation_url: v.optional(v.string()),
    external_url: v.optional(v.string()),
    attributes: v.size(v.array(v.object({
        trait_type: v.string(),
        value: v.union([v.string(), v.number()]),
        display_type: v.optional(v.enums(['Date'])),
        max_value: v.optional(v.number()),
        trait_count: v.optional(positive(integer(v.number()))),
    })), 0, 60),
    collection: v.optional(v.object({
        name: v.string(),
        family: v.string(),
    })),
    properties: v.type({
        files: v.array(v.object({
            uri: v.string(),
            type: v.string(),
            cdn: v.optional(v.string()),
        })),
        category: v.enums(['image', 'video', 'audio', 'vr', 'html']),
        creators: v.array(v.object({
            address: v.size(v.string(), 1, 64),
            share: v.max(v.min(positive(integer(v.number())), 0), 100),
        })),
    }),
});
