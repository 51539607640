import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { get } from 'lodash-es';
import style from './WrappedInput.module.scss';

/**
 * Base input class
 */

export function wrapInput(type: string, fn: IWrappedInput) {
  return function WrappedInput<T, U extends keyof T>(
    props: Props<T, U>,
  ): ReactElement<any, any> | null {
    const [error, setError] = useState(props.error);
    const [value, setValue] = useState(
      props.field ? get(props.value, props.field) : props.value,
    );
    const [state, setState] = useState(InputState.NORMAL);

    useEffect(() => {
      setError(props.error);
      if (props.error && props.error.length) setState(InputState.INVALID);
      else setState(InputState.NORMAL);
    }, [props.error]);

    const disabled = useMemo(
      () => props.enabled === false || props.editable === false,
      [props.editable, props.enabled],
    );

    useEffect(() => {
      if (props.editable === false) setState(InputState.FROZEN);
      else if (props.enabled === false) setState(InputState.DISABLED);
      else setState(InputState.NORMAL);
    }, [props.editable, props.enabled]);

    const focus = useCallback(() => setState(InputState.FOCUSED), [setState]);
    const change = useCallback(
      (e: any) => setValue(e.target ? e.target.value : e),
      [setValue],
    );

    const update = useCallback(
      async (val: any) => {
        if (props.format) val = props.format(val);

        setValue(val);
        props.onChange(val);

        try {
          if (props.validate) await props.validate(val);

          setError(undefined);
          setState(InputState.VALID);
        } catch (e: any) {
          setError(e.message || e);
          setState(InputState.INVALID);
        }
      },
      [props],
    );

    const input = useCallback(
      (e: any) => {
        setValue(e.target ? e.target.value : e);
        update(e.target ? e.target.value : e);
      },
      [update],
    );

    const blur = useCallback(async () => {
      setState(InputState.LOADING);
      update(value);
    }, [value, update]);

    const wrappedProps = useMemo(
      () => ({
        ...props,
        focus,
        blur,
        change,
        input,
        update,
        disabled,
        state,
        val: value,
      }),
      [props, focus, blur, change, input, update, disabled, state, value],
    );

    // When prop value changes, update internal state value
    useEffect(() => {
      setValue(props.field ? get(props.value, props.field) : props.value);
    }, [props.field, props.value]);

    return (
      <div className={`${style.input} ${props.className}`}>
        {props.title && props.title.length && type !== 'checkbox' && (
          <label {...(props.field && { htmlFor: props.field })}>
            {props.title}
          </label>
        )}

        {props.preview && props.preview.length && (
          <div className={style.preview}>
            <span className="muted">{props.preview}</span>
            {value}
          </div>
        )}

        {type === 'checkbox' ? (
          <label
            style={{ display: 'flex', textTransform: 'none' }}
            {...(props.field && { htmlFor: props.field })}
          >
            {fn(wrappedProps)} {props.title}
          </label>
        ) : (
          fn(wrappedProps)
        )}

        {error && error.length && <div className={style.error}>{error}</div>}

        {props.children && type !== 'select' && type !== 'checkbox' && (
          <div className={style.description}>{props.children}</div>
        )}
      </div>
    );
  };
}

export type IWrappedInput = <T, U extends keyof T>(
  props: IWrappedProps<T, U>,
) => ReactElement<any, any> | null;

export type IWrappedProps<T, U extends keyof T> = Props<T, U> & {
  focus: () => void;
  blur: () => void;
  change: <X>(event: React.FormEvent<X>) => void;
  input: <X>(event: React.FormEvent<X>) => void;
  update: (value: T[U]) => void;
  disabled: boolean;
  state: InputState;
  val: T[U];
  checked?: boolean;
};

export type GFC<T, U extends keyof T> = React.FC<IInputProps<T, U>>;
type Props<T, U extends keyof T> = PropsWithChildren<IInputProps<T, U>> & {
  className?: string;
};

export interface IInputProps<T, U extends keyof T> {
  title?: string;
  type?: 'input' | 'text' | 'number' | 'url';
  field?: string | undefined;
  value: T;
  error?: string;
  preview?: string;
  unit?: string;
  placeholder?: string;
  options?: Record<string, any>;
  format?: (v: any) => any;
  validate?: (v: T[U]) => void | Promise<void>;
  onChange: (val: T[U]) => unknown;
  onInput?: (val: T[U]) => unknown;
  pattern?: string;
  editable?: boolean;
  enabled?: boolean;
  fileBaseName?: string;
  maxRows?: number;
  checked?: boolean;
  hasError?: string;
}

export enum InputState {
  NORMAL = 'normal',
  FOCUSED = 'focused',
  FROZEN = 'frozen',
  DISABLED = 'disabled',
  LOADING = 'loading',
  VALID = 'valid',
  INVALID = 'invalid',
}
