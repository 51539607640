import { ILaunchpadDraft } from '@creatorhub/shared';
import axios from 'axios';
import { useMemo, useState } from 'react';
import DataGrid, { FormatterProps, SortColumn } from 'react-data-grid';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { Loader } from '../../components/Loader';
import { backendUrl } from '../../config';
import { useAuth } from '../../contexts/Auth';

const COLUMNS = [
  {
    key: 'launchImage',
    name: 'pfp',
    frozen: true,
    sortable: false,
    width: 40,
    formatter(props: FormatterProps<ILaunchpadDraft>) {
      const value = props.row.assets.launchImage;
      return (
        <div>
          <img src={value} style={{ height: '34px' }} />
        </div>
      );
    },
  },
  {
    key: 'submissionStatus',
    name: 'Status',
    frozen: true,
    sortable: true,
    width: 100,
  },
  {
    key: 'symbol',
    name: 'Symbol',
    frozen: true,
    sortable: true,
    width: 200,
  },
  {
    key: 'name',
    name: 'Name',
    frozen: true,
    sortable: true,
    width: 200,
  },
  {
    key: 'author',
    name: 'Email',
    sortable: true,
    width: 200,
  },
];

export default function AdminLaunchpadDrafts() {
  const { token } = useAuth();
  const [searchText, setSearchText] = useState<string>('');
  const navigate = useNavigate();
  const [sortColumns, setSortColumns] = useState<SortColumn[]>([]);

  const collectionDrafts = useQuery<ILaunchpadDraft[]>({
    queryKey: ['admin-collection-drafts'],
    queryFn: () => {
      return axios
        .get(`${backendUrl}/api/admin/launchpad-drafts`, {
          headers: { authorization: `Bearer ${token.current}` },
        })
        .then(res => res.data);
    },
  });

  const filteredCollectionDrafts = useMemo<ILaunchpadDraft[]>(() => {
    if (!collectionDrafts.data) {
      return [];
    }

    let data: ILaunchpadDraft[];
    const textToSearch = searchText.trim().toLowerCase();
    if (textToSearch.length !== 0) {
      data = collectionDrafts.data.filter(item => {
        return (
          item.name.toLowerCase().includes(textToSearch) ||
          item.symbol.toLowerCase().includes(textToSearch) ||
          item.author.toLowerCase().includes(textToSearch)
        );
      });
    } else {
      data = collectionDrafts.data;
    }

    if (!sortColumns.length) {
      return data;
    }

    return data.sort((a, b) => {
      const invert = sortColumns[0].direction === 'ASC' ? 1 : -1;
      return (
        (
          a[sortColumns[0].columnKey as keyof ILaunchpadDraft] as string
        ).localeCompare(
          b[sortColumns[0].columnKey as keyof ILaunchpadDraft] as string,
        ) * invert
      );
    });
  }, [collectionDrafts.data, searchText, sortColumns]);

  if (collectionDrafts.data === undefined) {
    return <Loader />;
  }

  return (
    <div>
      <div className="flex my-2">
        <Button
          className="mr-2"
          variant="secondary"
          onClick={() => {
            collectionDrafts.refetch();
          }}
        >
          Refresh
        </Button>
        <label htmlFor="" className="py-2">
          Search
        </label>
        <Input
          value={searchText}
          onChange={(e: any) => {
            setSearchText(e.target.value);
          }}
        />
      </div>
      <DataGrid
        className="h-screen"
        columns={COLUMNS}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        rows={filteredCollectionDrafts}
        rowKeyGetter={(row: ILaunchpadDraft) => row._id}
        onRowClick={(row: ILaunchpadDraft) => {
          navigate(`/launchpad/draft/${row._id}`);
        }}
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
      />
    </div>
  );
}
