import { useEffect } from 'react';
export const useScript = (url, attrs) => useEffect(() => createScript(url, attrs), [url, attrs]);
export const createScript = (url, attrs) => {
    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    Object.entries(attrs || {}).forEach(([key, value]) => script.setAttribute(key, value));
    document.body.appendChild(script);
    return () => {
        document.body.removeChild(script);
    };
};
