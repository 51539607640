export * from './user';
export const LAUNCHPAD_ROYALTY_ADDRESS = 'RRUMF9KYPcvNSmnicNMAFKx5wDYix3wjNa6bA7R6xqA';
export const DEFAULT_LAUNCHPAD_ROYALTY_SHARE = 10;
// Discount Code -> New Royalty %
export const ROYALTY_DISCOUNT_CODES = {
    u82173213u: 9,
    o0ou2kzn1h: 8,
    er1nsh0l8z: 7,
    mnw61tuqiz: 6,
    y0bw0c3d0c: 5,
    aa8923hn13: 4,
    jhuvy132gy: 3,
    h7123y9mkl: 2,
    eesj91nu12: 1,
    fr33m4g1c0: 0,
};
import { LaunchpadSubmissionState, LaunchpadSubmissionStatus, } from '../creators';
import { getUserLevel, UserRole } from './user';
/**
 * Roles
 */
const DEFAULT = {
    [LaunchpadSubmissionStatus.APPROVED]: [UserRole.Admin],
    [LaunchpadSubmissionStatus.DEPLOYED]: [UserRole.Admin],
    [LaunchpadSubmissionStatus.PENDING]: [UserRole.Admin, UserRole.Client],
    [LaunchpadSubmissionStatus.REJECTED]: [UserRole.Admin],
    [LaunchpadSubmissionStatus.SUBMITTED]: [UserRole.Admin],
};
const CLIENT_SPECIFIC = {
    [LaunchpadSubmissionStatus.APPROVED]: [UserRole.Client],
    [LaunchpadSubmissionStatus.DEPLOYED]: [UserRole.Client],
    [LaunchpadSubmissionStatus.PENDING]: [UserRole.Client],
    [LaunchpadSubmissionStatus.REJECTED]: [UserRole.Client],
    [LaunchpadSubmissionStatus.SUBMITTED]: [UserRole.Client],
};
const ADMIN_SPECIFIC = {
    [LaunchpadSubmissionStatus.APPROVED]: [UserRole.Admin],
    [LaunchpadSubmissionStatus.DEPLOYED]: [UserRole.Admin],
    [LaunchpadSubmissionStatus.PENDING]: [UserRole.Admin],
    [LaunchpadSubmissionStatus.REJECTED]: [UserRole.Admin],
    [LaunchpadSubmissionStatus.SUBMITTED]: [UserRole.Admin],
};
const OPS_SPECIFIC = {
    [LaunchpadSubmissionStatus.APPROVED]: [UserRole.Ops],
    [LaunchpadSubmissionStatus.DEPLOYED]: [UserRole.Ops],
    [LaunchpadSubmissionStatus.PENDING]: [UserRole.Ops],
    [LaunchpadSubmissionStatus.REJECTED]: [UserRole.Ops],
    [LaunchpadSubmissionStatus.SUBMITTED]: [UserRole.Ops],
};
const BACKEND_SPECIFIC = {
    [LaunchpadSubmissionStatus.APPROVED]: [],
    [LaunchpadSubmissionStatus.DEPLOYED]: [],
    [LaunchpadSubmissionStatus.PENDING]: [],
    [LaunchpadSubmissionStatus.REJECTED]: [],
    [LaunchpadSubmissionStatus.SUBMITTED]: [],
};
const DEPLOY_DEP = {
    [LaunchpadSubmissionStatus.APPROVED]: [UserRole.Admin],
    [LaunchpadSubmissionStatus.DEPLOYED]: [UserRole.Ops],
    [LaunchpadSubmissionStatus.PENDING]: [UserRole.Client, UserRole.Admin],
    [LaunchpadSubmissionStatus.REJECTED]: [UserRole.Admin],
    [LaunchpadSubmissionStatus.SUBMITTED]: [UserRole.Admin],
};
/**
 * Helpers
 */
const isDoxxed = (whenTrue, whenFalse) => (newValue, oldValue) => !oldValue || oldValue.doxxed ? whenTrue : whenFalse;
/**
 * Fields
 */
export const LAUNCHPAD_FIELDS = {
    [LaunchpadSubmissionState.INTRO]: [],
    [LaunchpadSubmissionState.PEOPLE]: ['people'],
    [LaunchpadSubmissionState.DESCRIPTIONS]: ['richRoadmap', 'richDescription'],
    [LaunchpadSubmissionState.CONTENT]: ['assets', 'description', 'links'],
    [LaunchpadSubmissionState.GENERAL]: [
        'symbol',
        'name',
        'totalSupply',
        'nftSymbol',
    ],
    [LaunchpadSubmissionState.CREATORS]: [
        'creators',
        'sellerFeeBasisPoints',
        'treasury',
    ],
    [LaunchpadSubmissionState.ASSETS]: ['assets'],
    [LaunchpadSubmissionState.METADATA]: ['assets'],
    [LaunchpadSubmissionState.STAGES]: ['stages'],
    [LaunchpadSubmissionState.CONTRACT]: [
        'premintCount',
        'premintRecipient',
        'splTokenMint',
        'badges',
    ],
    [LaunchpadSubmissionState.SUBMIT]: ['submissionStatus'],
    [LaunchpadSubmissionState.APPROVAL]: ['submissionStatus'],
};
/**
 * Rules
 */
export const RULES = {
    _id: BACKEND_SPECIFIC,
    createdAt: BACKEND_SPECIFIC,
    updatedAt: BACKEND_SPECIFIC,
    deletedAt: BACKEND_SPECIFIC,
    submissionStatus: ADMIN_SPECIFIC,
    operations: BACKEND_SPECIFIC,
    visitedStates: CLIENT_SPECIFIC,
    completedStates: CLIENT_SPECIFIC,
    version: BACKEND_SPECIFIC,
    organization: BACKEND_SPECIFIC,
    author: BACKEND_SPECIFIC,
    name: DEFAULT,
    symbol: DEFAULT,
    nftSymbol: DEFAULT,
    description: DEFAULT,
    assets: {
        create: DEFAULT,
        remove: DEFAULT,
        update: {
            launchImage: DEFAULT,
            rootCID: DEFAULT,
            revealCID: DEFAULT,
            gateway: BACKEND_SPECIFIC,
            assetUploadCache: DEFAULT,
        },
    },
    signer: OPS_SPECIFIC,
    expectedMintDate: DEFAULT,
    totalSupply: DEFAULT,
    treasury: CLIENT_SPECIFIC,
    sellerFeeBasisPoints: DEPLOY_DEP,
    splTokenMint: DEFAULT,
    creators: {
        insert: DEFAULT,
        remove: DEFAULT,
        update: {
            _id: ADMIN_SPECIFIC,
            address: DEFAULT,
            share: DEFAULT,
        },
    },
    stages: {
        insert: DEFAULT,
        remove: DEFAULT,
        update: {
            _id: ADMIN_SPECIFIC,
            name: DEFAULT,
            displayName: DEFAULT,
            startTime: DEFAULT,
            endTime: DEFAULT,
            price: DEFAULT,
            walletLimit: DEFAULT,
            type: DEFAULT,
        },
    },
    premintCount: DEFAULT,
    premintRecipient: DEFAULT,
    requiresMintReveal: DEFAULT,
    devnet: {
        create: OPS_SPECIFIC,
        remove: OPS_SPECIFIC,
        update: {
            candyMachineId: OPS_SPECIFIC,
            escrow: OPS_SPECIFIC,
        },
    },
    mainnet: {
        create: OPS_SPECIFIC,
        remove: OPS_SPECIFIC,
        update: {
            candyMachineId: OPS_SPECIFIC,
            escrow: OPS_SPECIFIC,
        },
    },
    links: {
        create: DEFAULT,
        remove: DEFAULT,
        update: {
            twitter: DEFAULT,
            discord: DEFAULT,
            telegram: DEFAULT,
            website: DEFAULT,
            whitepaper: DEFAULT,
        },
    },
    richDescription: DEFAULT,
    richRoadmap: DEFAULT,
    people: {
        insert: DEFAULT,
        remove: DEFAULT,
        update: {
            _id: BACKEND_SPECIFIC,
            name: isDoxxed(ADMIN_SPECIFIC, DEFAULT),
            position: isDoxxed(ADMIN_SPECIFIC, DEFAULT),
            bio: isDoxxed(ADMIN_SPECIFIC, DEFAULT),
            doxxed: ADMIN_SPECIFIC,
            twitterLink: isDoxxed(ADMIN_SPECIFIC, DEFAULT),
            linkedinLink: isDoxxed(ADMIN_SPECIFIC, DEFAULT),
            websiteLink: isDoxxed(ADMIN_SPECIFIC, DEFAULT),
            type: isDoxxed(ADMIN_SPECIFIC, DEFAULT),
        },
    },
    /**
     * escrow_1d and doxxed are mandatory
     */
    badges: {
        insert: ADMIN_SPECIFIC,
        remove: ADMIN_SPECIFIC,
    },
};
/**
 * Helpers
 */
export const mergeWithPermittedFields = (newValue, oldValue, status, user) => update(newValue, oldValue, 
// TODO: how to determine Ops user?
getUserLevel(user), status, RULES);
export const update = (newValue, oldValue, role, status, permission) => {
    if (isObjectPerms(permission)) {
        if (canCreate(newValue, oldValue, permission.create, status, role))
            return update(newValue, undefined, role, status, permission.update);
        else if (canRemove(newValue, oldValue, permission.remove, status, role))
            return undefined;
        else if (canUpdateObj(newValue, oldValue, permission.update, status, role))
            return update(newValue, oldValue, role, status, permission.update);
    }
    else if (isArrayPerms(permission)) {
        const out = [];
        const newArr = newValue || [];
        const oldArr = oldValue || [];
        if (permission.update) {
            // non-primitives, must include _id
            const newIds = newArr.map(item => item._id);
            const oldIds = oldArr.map(item => item._id);
            // check to see all the items that we have removed we are allowed to
            oldIds
                .filter(id => !newIds.includes(id))
                .map(id => oldArr?.find(item => item._id === id))
                .forEach(item => {
                if (!canRemove(undefined, oldValue, permission.remove, status, role)) {
                    throw new Error('Do not have permission to remove from the array');
                }
            });
            // check to see all items we have added are validated permission-wise and
            // we are allowed to insert
            newIds
                .filter(id => id && !oldIds.includes(id))
                .map(id => newArr?.find(item => item._id === id))
                .forEach(item => {
                if (!canCreate(newValue, undefined, permission.insert, status, role)) {
                    throw new Error('Do not have permission to create items inside the array');
                }
                // need to validate contents of new items
                out.push(update(item, undefined, role, status, permission.update));
            });
            // allow for new documents that do not have an _id field initialized
            newArr
                .filter(item => !item._id)
                .forEach(item => {
                if (!canCreate(newValue, undefined, permission.insert, status, role)) {
                    throw new Error('Do not have permission to create items inside the array');
                }
                out.push(item);
            });
            newIds
                .filter(id => oldIds.includes(id))
                .map(id => [
                newArr?.find(item => item._id === id),
                oldArr?.find(item => item._id === id),
            ])
                .forEach(([newValue, oldValue]) => out.push(update(newValue, oldValue, role, status, permission.update)));
        }
        else {
            // primitives associate by value
            oldArr
                .filter(item => !newArr.includes(item))
                .filter(item => !canRemove(undefined, oldValue, permission.remove, status, role))
                .forEach(item => out.push(item));
            newArr
                .filter(item => !oldArr.includes(item))
                .filter(item => canCreate(newValue, undefined, permission.insert, status, role))
                .forEach(item => out.push(item));
        }
        return out;
    }
    else if (isFnPerms(permission)) {
        return update(newValue, oldValue, role, status, permission(newValue, oldValue));
    }
    else if (isPerms(permission)) {
        return permission[status].includes(role) ? newValue : oldValue;
    }
    else if (isDictPerms(permission)) {
        return Object.fromEntries(Object.entries(permission)
            .map(([key, perm]) => [
            key,
            update((newValue || {})[key], (oldValue || {})[key], role, status, perm),
        ])
            .filter(([_, value]) => value !== undefined));
    }
};
export const isArrayPerms = (perms) => 'insert' in perms && 'remove' in perms;
export const isObjectPerms = (perms) => 'create' in perms && 'remove' in perms;
export const isFnPerms = (perms) => typeof perms === 'function';
export const isDictPerms = (perms) => typeof perms === 'object' && perms !== null && Object(perms) === perms;
export const isPerms = (perms) => Object.values(LaunchpadSubmissionStatus).every(status => status in perms);
const canCreate = (newValue, oldValue, perms, status, role) => newValue !== undefined &&
    oldValue === undefined &&
    (isFnPerms(perms) ? perms(newValue, oldValue) : perms)[status].includes(role);
const canRemove = (newValue, oldValue, perms, status, role) => newValue === undefined &&
    oldValue !== undefined &&
    (isFnPerms(perms) ? perms(newValue, oldValue) : perms)[status].includes(role);
const canUpdateObj = (newValue, oldValue, perms, status, role) => newValue !== undefined && oldValue !== undefined;
