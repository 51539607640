import ReactDatePicker from 'react-datepicker';
import { wrapInput } from './WrappedInput';
import style from './Input.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatePicker.module.scss';

export const DatePicker = wrapInput('date-picker', props => {
  return (
    <div
      className={`${style.input} ${style[props.state]} ${
        props.hasError ? styles.error : ''
      }`}
    >
      <ReactDatePicker
        calendarClassName={styles.calendar}
        dayClassName={() => styles.day}
        placeholderText={props.placeholder}
        disabled={props.editable === false}
        selected={
          props.val && (props.val as any).length
            ? new Date(props.val as unknown as string)
            : undefined
        }
        minDate={props.options?.minDate}
        maxDate={props.options?.maxDate}
        onChange={date =>
          props.update(date instanceof Date ? date.toISOString() : ('' as any))
        }
        showTimeInput
        dateFormat={props.options!.format}
      />
    </div>
  );
}) as any;
