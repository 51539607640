/**
 * Config
 */
const endpoint = {
    devnet: 'https://psytrbhymqlkfrhudd.dev.genesysgo.net:8899',
    testnet: 'https://api.testnet.solana.com',
    'mainnet-beta': 'https://ssc-dao.genesysgo.net',
};
/**
 * Retrieves the RPC API URL for the specified cluster
 */
export function clusterApiUrl(cluster) {
    if (!cluster) {
        return endpoint['devnet'];
    }
    const url = endpoint[cluster];
    if (!url) {
        throw new Error(`Unknown cluster: ${cluster}`);
    }
    return url;
}
