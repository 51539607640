import {
  getMEMarketplaceBaseURL,
  getMetaplexCertifiedCollectionVolumes,
  IVolumeMCC,
} from '@creatorhub/shared';
import { useEffect, useMemo, useRef, useState } from 'react';
import DataGrid, { FormatterProps, SortColumn } from 'react-data-grid';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './AdminActivityMonitorMCC.module.scss';
import LoadingBar from 'react-top-loading-bar';
import { Button } from '../../components/Button/Button';
import { Loader } from '../../components/Loader';
import axios from 'axios';
import { backendUrl } from '../../config';
import { useAuth } from '../../contexts/Auth';
import { useToggle } from '../../hooks/useToggle';
import { Input } from '../../components/Input/Input';
toast.configure({
  position: 'top-right',
  autoClose: 5000,
  theme: 'dark',
});

const COLUMNS = [
  {
    key: '_id',
    name: 'Address',
    width: 600,
    sortable: true,
    formatter(props: FormatterProps<IVolumeMCC>) {
      const { _id, isBlacklisted } = props.row;
      return (
        <div className="space-x-5">
          {isBlacklisted && <span className="text-red-500">BLACKLISTED</span>}
          <span>{_id}</span>
        </div>
      );
    },
  },
  {
    key: 'volumeType',
    name: 'Volume coming from',
    sortable: true,
  },
  {
    key: 'value30d',
    name: 'Volume 30D',
    sortable: true,
  },
  {
    key: 'value7d',
    name: 'Volume 7D',
    sortable: true,
  },
  {
    key: 'value1d',
    name: 'Volume 24H',
    sortable: true,
  },
  {
    key: 'value6h',
    name: 'Volume 6H',
    sortable: true,
  },
  {
    key: 'links',
    name: 'Links',
    formatter(props: FormatterProps<IVolumeMCC>) {
      const { _id } = props.row;
      return (
        <div className="flex justify-between text-accent font-bold">
          <a
            href={`${getMEMarketplaceBaseURL()}${_id}`}
            target="_blank"
            rel="noreferrer"
          >
            MARKETPLACE
          </a>
          <a
            href={`https://solscan.io/token/${_id}`}
            target="_blank"
            rel="noreferrer"
          >
            SOL SCAN
          </a>
        </div>
      );
    },
  },
];

type Comparator = (a: any, b: any) => number;
function getComparator(sortColumn: string): Comparator {
  switch (sortColumn) {
    case 'value30d':
    case 'value7d':
    case 'value1d':
    case 'value6h':
      return (a, b) => {
        return (
          Number(a[sortColumn].replace('◎ ', '')) -
          Number(b[sortColumn].replace('◎ ', ''))
        );
      };
    case 'volumeType':
      return (a, b) => {
        return (a[sortColumn] ?? '').localeCompare(b[sortColumn] ?? '');
      };
    default:
      throw new Error(`unsupported sortColumn: ${sortColumn}`);
  }
}

export default function AdminActivityMonitorMCC() {
  const loadingBarRef = useRef(null);
  const { token } = useAuth();
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [showBlacklisted, toggleShowBlacklisted] = useToggle(false);
  const [blacklistedAddresses, setBlacklistedAddresses] = useState<
    Array<string>
  >([]);

  const volumeMCCQuery = useQuery<IVolumeMCC[]>({
    queryKey: ['admin-mcc-volumes'],
    queryFn: () => {
      return getMetaplexCertifiedCollectionVolumes();
    },
  });

  const bulkCheckMutation = useMutation<never, Error, { ids: string[] }>({
    mutationFn: params =>
      axios.post(`${backendUrl}/api/admin/blacklist/bulk-check`, params, {
        headers: { authorization: `Bearer ${token.current}` },
      }),
    onError: (err: unknown) => {
      if (axios.isAxiosError(err)) {
        toast.error(err.response?.data.msg || 'internal server error');
      } else if (err instanceof Error) {
        toast.error(err.message);
      } else {
        console.error(err);
      }
    },
    onSuccess: ({ data }: any) => {
      setBlacklistedAddresses(
        data.data.map((blacklist: any) => blacklist.value),
      );
    },
  });

  useEffect(() => {
    if (volumeMCCQuery.data) {
      bulkCheckMutation.mutate({
        ids: volumeMCCQuery.data.map(({ _id }) => _id),
      });
    }
    // eslint-disable-next-line
  }, [volumeMCCQuery.data]);

  const rows = useMemo(() => {
    const volumeData = [...(volumeMCCQuery.data || [])];
    for (let index = 0; index < volumeData.length; index++) {
      if (
        !showBlacklisted &&
        blacklistedAddresses.includes(volumeData[index]._id)
      ) {
        volumeData.splice(index, 1);
      } else if (blacklistedAddresses.includes(volumeData[index]._id)) {
        volumeData[index] = { ...volumeData[index], isBlacklisted: true };
      }
    }
    return volumeData;
  }, [volumeMCCQuery.data, blacklistedAddresses, showBlacklisted]);

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) {
      return rows;
    }
    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  useEffect(() => {
    if (!volumeMCCQuery.isFetching && loadingBarRef.current) {
      (loadingBarRef.current as any).complete();
    }
  }, [volumeMCCQuery.isFetching]);

  if (!volumeMCCQuery.data) {
    return <Loader />;
  }

  return (
    <>
      <div className="flex my-2">
        <Button
          className="mr-2"
          variant="secondary"
          onClick={() => {
            if (loadingBarRef.current) {
              (loadingBarRef.current as any).continuousStart();
            }
            volumeMCCQuery.refetch();
          }}
        >
          refresh
        </Button>
        <Link
          className="leading-[24px] py-2 px-4"
          to="/admin/collection-drafts"
        >
          Collection Listing
        </Link>

        <div className="flex items-center ml-4 text-black">
          <Input
            field="isFullyMinted"
            className="w-8"
            type="checkbox"
            onChange={toggleShowBlacklisted}
          />
          <label htmlFor="isFullyMinted" className="mr-2 text-white">
            Show Blacklisted
          </label>
        </div>
      </div>
      <div className="flex items-center justify-between py-2">
        <div className="mr-4">Total: {sortedRows.length}</div>
      </div>
      <DataGrid
        className={`h-screen ${styles['react-grid-Cell']}`}
        columns={COLUMNS}
        rowKeyGetter={(row: IVolumeMCC) => row._id}
        rows={sortedRows}
        sortColumns={sortColumns}
        onSortColumnsChange={setSortColumns}
        defaultColumnOptions={{
          sortable: false,
          resizable: true,
        }}
      />
      <LoadingBar ref={loadingBarRef} />
    </>
  );
}
