import { evaluateFlags, FeatureFlags, FeatureFlagKeys, FeatureFlagVariants, getNetwork, } from '@creatorhub/shared';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../contexts/Auth';
const FLAG_KEYS = Object.keys(FeatureFlagKeys);
export function useFeatureFlags({ context, options, } = {}) {
    const { user } = useAuth();
    const { data: features = FeatureFlags, ...queryResult } = useQuery({
        ...options,
        queryKey: ['feature-flags', context, user?.email ?? ''],
        queryFn: async () => {
            const flags = await evaluateFlags(FLAG_KEYS, {
                entityContext: {
                    ...context,
                    network: getNetwork(),
                    email: user?.email ?? '',
                },
            });
            const nextFeatures = { ...FeatureFlags };
            for (const key of FLAG_KEYS) {
                nextFeatures[key] =
                    flags[key] ?? FeatureFlagVariants.Off;
            }
            return nextFeatures;
        },
    });
    return { features, ...queryResult };
}
