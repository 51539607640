import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/Auth';
import { LogoMagicEden } from './LogoMagicEden';
import './Header.scss';
import { FeatureFlagVariants, isAdmin } from '@creatorhub/shared';
import { useFeatureFlags } from '../hooks/useFeatureFlagEnabled';

export const Header = () => {
  const { user } = useAuth();
  const shouldShowAdmin = isAdmin(user);
  const { features } = useFeatureFlags();

  const launchpadEnabled =
    features.creatorPortalLaunchpadForm === FeatureFlagVariants.On;

  return (
    <nav className="container header">
      <Link to="/" className="ma-logo ma-logo--me">
        <LogoMagicEden />
      </Link>

      <div className="menu">
        {user && (
          <Link className="menu__item" to={`/dashboard`}>
            <span className="ml-2">Dashboard</span>
          </Link>
        )}

        {shouldShowAdmin && launchpadEnabled && (
          <Link className="menu__item" to="/admin/launchpads">
            <span>Launchpad Admin Dashboard</span>
          </Link>
        )}
        {shouldShowAdmin && (
          <Link className="menu__item" to="/admin">
            <span>Admin dashboard</span>
          </Link>
        )}
        {user && (
          <Link className="menu__item" to="/logout">
            Sign out
          </Link>
        )}
      </div>
    </nav>
  );
};
