import styles from './ErrorHint.module.scss';
import cns from 'classnames';

export interface ErrorHintProps {
  className?: string;
  children: React.ReactNode;
}
export const ErrorHint = ({ children, className }: ErrorHintProps) => {
  return (
    <div className={cns(className, styles.msg, 'py-2 px-3')}>{children}</div>
  );
};
