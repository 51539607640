import { useState, useEffect } from 'react';
import { backendUrl, magicLink } from '../config';
import { magic, useAuth } from '../contexts/Auth';
import { createScript } from '../hooks/useScript';
import { Loader } from './Loader';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { AnalyticEvent, analytics } from '../analytics';

export interface LoginProps {
  redirectOnSuccess?: string;
}

export const Login = ({ redirectOnSuccess }: LoginProps) => {
  const { user, setUser, token } = useAuth();
  const [showLoader, setShowLoader] = useState(true);
  const [isBlacklisted, setBlacklisted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search).entries(),
      );

      if (params.provider && params.state) {
        // oauth callback
        const result = await magic.oauth.getRedirectResult();
        try {
          const user = await axios.get(`${backendUrl}/api/user/me`, {
            headers: { authorization: `Bearer ${result.magic.idToken}` },
          });

          setUser({
            id: result.magic.userMetadata.issuer!,
            email: result.magic.userMetadata.email || undefined,
            _id: user.data._id || '',
          });
          token.current = result.magic.idToken;
        } catch (err: unknown) {
          if (axios.isAxiosError(err)) {
            if (err.response?.data.msg === 'blacklisted') {
              setBlacklisted(true);
            }
          }
          console.error(err);
        }
      } else if (params.magic_credential) {
        // email login callback
        const didToken = await magic.auth.loginWithCredential();
        const metadata = await magic.user.getInfo();

        try {
          const user = await axios.get(`${backendUrl}/api/user/me`, {
            headers: { authorization: `Bearer ${didToken}` },
          });

          setUser({
            id: metadata.issuer!,
            email: metadata.email || undefined,
            _id: user.data._id || '',
          });
          token.current = didToken!;

          // log event when a new user signs up
          if (user.data.isNewUser) {
            analytics.trackAggregate({
              eventName: AnalyticEvent.NEW_USER_SIGNED_UP,
              email: metadata.email || undefined,
            });
          }
        } catch (err: unknown) {
          if (axios.isAxiosError(err)) {
            if (err.response?.data.msg === 'blacklisted') {
              setBlacklisted(true);
            }
          }
          console.error(err);
        }
      } else if (params.didt) {
        // returning user callback
        createScript('https://auth.magic.link/pnp/callback', {
          'data-magic-publishable-api-key': magicLink.key,
          'data-redirect-uri': window.location.href,
        });
      } else {
        const loggedIn = await magic.user.isLoggedIn();

        if (!loggedIn) {
          // render login ui
          createScript('https://auth.magic.link/pnp/login', {
            'data-magic-publishable-api-key': magicLink.key,
            'data-redirect-uri': window.location.href,
          });
          setShowLoader(false);
        } else {
          const metadata = await magic.user.getInfo();
          token.current = await magic.user.generateIdToken();

          try {
            const user = await axios.get(`${backendUrl}/api/user/me`, {
              headers: { authorization: `Bearer ${token.current}` },
            });

            setUser({
              id: metadata.issuer!,
              email: metadata.email || undefined,
              _id: user.data?._id,
            });
            if (redirectOnSuccess) {
              navigate(redirectOnSuccess);
            }
          } catch (err: unknown) {
            if (axios.isAxiosError(err)) {
              if (err.response?.data.msg === 'blacklisted') {
                setBlacklisted(true);
              }
            }
            console.error(err);
          }
        }
      }
    })();
  }, [user, setUser, token, redirectOnSuccess, navigate]);

  if (isBlacklisted) {
    return <h1>Sorry, you cannot use the Creator Portal</h1>;
  }
  if (showLoader) {
    return <Loader />;
  }
  return null;
};
