/**
 * Cluster Provider
 */

import { ReactNode, createContext, useContext } from 'react';

export type ICluster = 'devnet' | 'mainnet-beta';

export type IClusterContext = {
  cluster: ICluster;
};

export const ClusterContext = createContext<IClusterContext>(
  null as unknown as IClusterContext,
);

export const ClusterProvider: React.FC<{
  initial: ICluster;
  children?: ReactNode;
}> = ({ children, initial }) => {
  return (
    <ClusterContext.Provider value={{ cluster: initial }}>
      {children}
    </ClusterContext.Provider>
  );
};

export const useCluster = () => useContext(ClusterContext);
