import * as v from 'superstruct';
import { isodate } from '../util';
export const EthTxBaseStruct = v.object({
    symbol: v.string(),
    chainId: v.number(),
    gasLimit: v.optional(v.string()),
    maxFeePerGas: v.optional(v.string()),
    nonce: v.optional(v.string()),
    maxPriorityFeePerGas: v.optional(v.string()),
});
export const EthTxStruct = v.assign(EthTxBaseStruct, v.object({
    operationName: v.string(),
    operationParams: v.object(),
}));
export const EthDeployStruct = v.assign(v.omit(EthTxStruct, ['operationName', 'symbol']), v.object({
    contractBinary: v.object(),
    confirmationsToWait: v.number(),
}));
export const IContractStorageStruct = v.assign(EthTxBaseStruct, v.object({
    /**
     * Mongoose
     */
    _id: v.union([v.string(), v.any()]),
    createdAt: isodate(v.string()),
    updatedAt: isodate(v.string()),
    name: v.string(),
    contractJsonAbi: v.array(v.object()),
    contractAddress: v.string(),
    ownedByME: v.boolean(),
}));
export const WalletStorageStruct = v.assign(EthTxBaseStruct, v.object({
    /**
     * Mongoose
     */
    _id: v.union([v.string(), v.any()]),
    createdAt: isodate(v.string()),
    updatedAt: isodate(v.string()),
    encryptedPrivateKey: v.string(),
    address: v.string(),
    encryptedMnemonic: v.string(),
}));
