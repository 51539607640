import cns from 'classnames';
import styles from './Button.module.scss';
import {
  Button as MantineButton,
  ButtonProps as MantineButtonProps,
} from '@mantine/core';

export interface ButtonProps {
  className?: string;
  variant: 'primary' | 'secondary' | 'transparent';
  onClick?: () => void;
  children: React.ReactNode;
  type?: 'button' | 'submit';
  disabled?: boolean;
  style?: any;
}
export const Button = ({
  variant,
  onClick,
  children,
  className,
  type = 'button',
  disabled = false,
  style,
}: ButtonProps) => {
  return (
    <button
      style={{
        ...style,
      }}
      className={cns(className, styles.btn, 'py-3 px-4 hover:opacity-80', {
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
      })}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export const ButtonV2 = (
  props: MantineButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>,
) => {
  return <MantineButton color="pink" {...props} />;
};
