import { OAuthExtension } from '@magic-ext/oauth';
import axios from 'axios';
import { Magic } from 'magic-sdk';
import React, {
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { analytics } from '../analytics';
import { backendUrl, magicLink } from '../config';
import { useInterval } from '../hooks/useInterval';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Use magiclink sdk
 */

export const magic = new Magic(magicLink.key, {
  extensions: [new OAuthExtension()],
  testMode: magicLink.testMode,
});

export const AuthProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<IUser>();
  const token = useRef<string>();

  useEffect(() => {
    // set current user for sending analytics data
    if (user && user.id) {
      analytics.identity({ userId: user.id });
      datadogRum.setUser(user);
    } else {
      analytics.reset();
    }
  }, [user]);

  const clearUser = useCallback(() => {
    setUser(undefined);
    token.current = undefined;
  }, [setUser]);

  const renewToken = useCallback(async () => {
    console.log('Renewing token...');
    if (!user) return;
    const res = await magic.user.getIdToken({ lifespan: 15 * 60 * 1000 });
    if (!user) return;
    token.current = res;
  }, [user]);

  useInterval(renewToken, 10 * 60 * 1000); // updates token every 10 mins

  const profile = useQuery({
    queryKey: ['user', user?.id],
    queryFn: () =>
      axios
        .get(`${backendUrl}/api/user/me`, {
          headers: {
            authorization: `Bearer ${token.current}`,
          },
        })
        .then(res => res.data),
    enabled: Boolean(user) && Boolean(token.current),
  });

  const isAdmin = user?.email?.endsWith('@magiceden.io') || false;

  const value = useMemo(
    () => ({
      user,
      setUser,
      clearUser,
      token,
      authorizationHeader: `Bearer ${token.current}`,
      profile,
      renewToken,
      isAdmin,
    }),
    [user, clearUser, profile, renewToken, token, setUser, isAdmin],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

/**
 * Store info about currently logged-in user
 */

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

/**
 * Expose a hook for convenience
 */

export const useAuth = () => useContext(AuthContext);

/**
 * Interfaces
 */

export type IUser = {
  id: string;
  email?: string;
  _id?: string;
};

export type IAuthContext = {
  user: IUser | undefined;
  setUser: (value: IUser | undefined) => void;
  clearUser: () => unknown;
  profile: UseQueryResult<any, unknown>;
  token: IToken;
  authorizationHeader: string;
  renewToken: () => Promise<void>;
  isAdmin: boolean;
};

export type IToken = MutableRefObject<string | undefined>;
