import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { AnalyticEvent, analytics } from '../analytics';
import { backendUrl } from '../config';
import { useAuth } from '../contexts/Auth';
const DRAFT_COLLECTIONS_KEY = 'draft-collections';
const ELIGIBLE_COLLECTIONS_KEY = 'eligible-for-verification';
export const useCollections = (excludeChains) => {
    const { token } = useAuth();
    return useQuery({
        queryKey: [DRAFT_COLLECTIONS_KEY],
        queryFn: () => axios
            .get(`${backendUrl}/api/collection-drafts` +
            (excludeChains ? '?excludeChains=' + excludeChains : ''), {
            headers: { authorization: `Bearer ${token.current}` },
        })
            .then(({ data }) => data),
    });
};
export const useCreateCollection = (source) => {
    const { token, user } = useAuth();
    const navigate = useNavigate();
    return useMutation({
        mutationFn: () => {
            return axios
                .post(`${backendUrl}/api/collection-drafts/new`, {}, {
                headers: { authorization: `Bearer ${token.current}` },
            })
                .then(res => {
                return res.data;
            });
        },
        onSuccess: response => {
            analytics.trackAggregate({
                eventName: AnalyticEvent.COLLECTION_DRAFT_CREATED,
                email: user?.email,
                _id: response._id,
                organization: response.organization,
                author: response.author,
            });
            console.log(`[useCreateCollection] collection-drafts/new success. Routing...`);
            navigate(`/dashboard/listing/${response._id}/intro?source=${source ? source : 'listings'}`);
        },
    });
};
export const useDeleteCollection = () => {
    const { token } = useAuth();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: collectionId => {
            return axios
                .delete(`${backendUrl}/api/collection-drafts/${collectionId}`, {
                headers: { authorization: `Bearer ${token.current}` },
            })
                .then(res => {
                return res.data;
            });
        },
        async onMutate(collectionId) {
            const prevData = queryClient.getQueryData([
                DRAFT_COLLECTIONS_KEY,
            ]);
            queryClient.setQueryData([DRAFT_COLLECTIONS_KEY], prev => prev?.filter(item => item._id !== collectionId) ?? []);
            return { prevData };
        },
        onError(err, _, context) {
            const { prevData } = context;
            if (prevData) {
                queryClient.setQueryData([DRAFT_COLLECTIONS_KEY], prevData);
            }
        },
        onSettled() {
            queryClient.invalidateQueries({ queryKey: [DRAFT_COLLECTIONS_KEY] });
        },
    });
};
export const useEligibleCollection = () => {
    const { token } = useAuth();
    return useQuery({
        queryKey: [ELIGIBLE_COLLECTIONS_KEY],
        queryFn: () => axios
            .get(`${backendUrl}/api/verification/eligible-for-verification`, {
            headers: { authorization: `Bearer ${token.current}` },
        })
            .then(({ data }) => data),
    });
};
export const useApplyForVerification = () => {
    const { token } = useAuth();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (collectionId) => {
            return axios
                .post(`${backendUrl}/api/verification/apply`, { id: collectionId }, {
                headers: { authorization: `Bearer ${token.current}` },
            })
                .then(res => {
                return res.data;
            });
        },
        onError(err) {
            console.log(err);
        },
        onSettled() {
            queryClient.invalidateQueries({ queryKey: [ELIGIBLE_COLLECTIONS_KEY] });
        },
    });
};
