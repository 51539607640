import axios from 'axios';
import { getMEURL } from '../services';
export async function getMetaplexCertifiedCollectionVolumes() {
    return axios
        .get(`${getMEURL()}/metaplex-certified-collections-volumes`)
        .then(res => {
        const localizedVolumes = res.data.map((volume) => ({
            ...volume,
            value30d: `◎ ${Number(volume.value30d).toFixed(2).toLocaleString()}`,
            value7d: `◎ ${Number(volume.value7d).toFixed(2).toLocaleString()}`,
            value1d: `◎ ${Number(volume.value1d).toFixed(2).toLocaleString()}`,
            value6h: `◎ ${Number(volume.value6h).toFixed(2).toLocaleString()}`
        }));
        return localizedVolumes;
    });
}
