import styles from './Input.module.scss';
import cns from 'classnames';

export const Textarea = (props: any) => {
  const { errorStyle } = props;
  return (
    <div className={cns(styles.textarea, props.className)}>
      <textarea
        id={props.field}
        name={props.field ?? props.name}
        value={props.value as any}
        onFocus={props.focus}
        onInput={props.input}
        onChange={props.onChange}
        onBlur={props.blur}
        disabled={props.disabled}
        style={{
          ...(errorStyle && props.hasError ? errorStyle : {}),
        }}
        placeholder={props.placeholder}
        className={cns(props.inputClassName, {
          [styles.error]: props.hasError && !errorStyle,
          [styles.disabled]: props.disabled,
        })}
      />
    </div>
  );
};
