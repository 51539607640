import { getRudderParams, getNetwork, getMEURL, getEnv, } from '@creatorhub/shared/services';
import * as rudderStack from 'rudder-sdk-js';
import { i18n } from './i18n';
import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
export const INSTRUMENTATION_EVENT_CREATOR_HUB = 'instrumentation_event_creator_hub';
const EventDictionary = new Set();
/**
 *  Types
 */
export var AnalyticEvent;
(function (AnalyticEvent) {
    AnalyticEvent["NEW_USER_SIGNED_UP"] = "new_user_signed_up";
    AnalyticEvent["COLLECTION_DRAFT_CREATED"] = "collection_draft_created";
    AnalyticEvent["COLLECTION_DRAFT_SUBMITTED"] = "collection_draft_submitted";
    AnalyticEvent["COLLECTION_DRAFT_APPROVED"] = "collection_draft_approved";
    AnalyticEvent["CREATOR_ANNOUNCEMENT_DRAFT_SAVED"] = "creator_announcement_draft_saved";
    AnalyticEvent["CREATOR_ANNOUNCEMENT_SUBMIT"] = "creator_announcement_submit";
    AnalyticEvent["CREATOR_ANNOUNCEMENT_REVISE"] = "creator_announcement_revise";
    AnalyticEvent["CREATOR_ANNOUNCEMENT_APPROVED"] = "creator_announcement_approved";
    AnalyticEvent["CREATOR_ANNOUNCEMENT_REJECTED"] = "creator_announcement_rejected";
    AnalyticEvent["CCREATOR_ANNOUNCEMENT_ARCHIVED"] = "reator_announcement_archived";
})(AnalyticEvent || (AnalyticEvent = {}));
export var PageCategory;
(function (PageCategory) {
    PageCategory["COLLECTION_DRAFT_SUBMISSION"] = "collection_draft_submission";
})(PageCategory || (PageCategory = {}));
/**
 * Initialize
 */
rudderStack.ready(() => console.log('analytics init.'));
const [rudderKey, websiteUrl, extras] = getRudderParams();
rudderStack.load(rudderKey, websiteUrl, extras);
const { hostname: siteName } = window.location;
class Analytics {
    /**
     * Track page visits
     */
    page({ category, name, properties } = {}) {
        rudderStack.page(category, name, {
            ...properties,
            siteName,
            lang: i18n.language,
        });
    }
    /**
     * Track event
     */
    // @deprecated - use trackAggregate instead
    track({ event, properties }) {
        rudderStack.track(event, {
            ...properties,
            siteName,
            lang: i18n.language,
        });
    }
    /**
     * Identify a visiting user and associate them to their actions
     * and store users traits like email, name, etc
     *
     * when to call https://www.rudderstack.com/docs/rudderstack-api/api-specification/rudderstack-spec/identify/#when-should-i-call-identify
     */
    identity({ userId }) {
        rudderStack.identify(userId, {
            siteName,
            lang: i18n.language,
        });
    }
    // writes all events to a singular table in DBC, `instrumentation_event_creator_hub` table
    // update event dictionary for new events via Retool
    // https://retool.magiceden.io/apps/Insights/event_dictionary
    async trackAggregate({ eventName, ...properties }) {
        if (!eventName) {
            if (this.isLocalhost()) {
                console.log(`Cannot track event ${INSTRUMENTATION_EVENT_CREATOR_HUB}, properties.eventName is required.`);
            }
            return;
        }
        const internalEventName = `rudderstack.${eventName}`;
        const isEventValid = await this.isEventValid(internalEventName);
        const params = {
            properties: JSON.stringify({
                eventName,
                ...properties,
            }),
            siteName,
            lang: i18n.language,
            page: location.pathname,
            href: location.href,
            env: getNetwork(),
        };
        if (isEventValid) {
            rudderStack.track(INSTRUMENTATION_EVENT_CREATOR_HUB, params);
        }
        // we want some flexibility that even if the event is not in event dictionary, still log to datadog RUM
        datadogRum.addAction(eventName, params);
        if (this.isLocalhost()) {
            console.info(`[event: ${INSTRUMENTATION_EVENT_CREATOR_HUB} - ${internalEventName}]`, {
                params,
            });
        }
    }
    async isEventValid(eventName) {
        if (EventDictionary.has(eventName)) {
            return true;
        }
        const res = await axios.get(`${getMEURL()}/event_dictionary/${eventName}`);
        if (res.data) {
            EventDictionary.add(eventName);
            return true;
        }
        return false;
    }
    /**
     * Reset current user identity
     */
    reset() {
        rudderStack.reset();
    }
    isLocalhost() {
        return getEnv() === 'local';
    }
}
export const analytics = new Analytics();
