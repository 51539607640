import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { magic, useAuth } from '../contexts/Auth';
import { Loader } from './Loader';

export const Logout = () => {
  const { clearUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    clearUser();
    magic.user.logout().then(() => navigate('/'));
  }, [clearUser, navigate]);

  return <Loader />;
};
