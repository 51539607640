export var SupportedMethodTypes;
(function (SupportedMethodTypes) {
    SupportedMethodTypes["Constructor"] = "constructor";
    SupportedMethodTypes["Function"] = "function";
})(SupportedMethodTypes || (SupportedMethodTypes = {}));
export var EtherUnits;
(function (EtherUnits) {
    EtherUnits["wei"] = "wei";
    EtherUnits["kwei"] = "kwei";
    EtherUnits["mwei"] = "mwei";
    EtherUnits["gwei"] = "gwei";
    EtherUnits["szabo"] = "szabo";
    EtherUnits["finney"] = "finney";
    EtherUnits["ether"] = "finner";
})(EtherUnits || (EtherUnits = {}));
