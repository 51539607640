import { useAuth } from '../contexts/Auth';
import { Login } from './Login';

export const UserRoute: React.FC<Record<string, any>> = ({
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  if (user?.id) return <Component {...rest} />;
  return <Login />;
};

export const AdminRoute: React.FC<Record<string, any>> = ({
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  if (user?.id) {
    if (user?.email?.endsWith('@magiceden.io')) {
      return <Component {...rest} />;
    } else {
      return <h1>Unauthorized</h1>;
    }
  }
  return <Login />;
};
