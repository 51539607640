/**
 * Shared Schema
 */
export var UserRole;
(function (UserRole) {
    UserRole[UserRole["Client"] = 0] = "Client";
    UserRole[UserRole["Ops"] = 1] = "Ops";
    UserRole[UserRole["Admin"] = 2] = "Admin";
})(UserRole || (UserRole = {}));
export const isAdmin = (user) => {
    return user?.email?.endsWith('@magiceden.io') ?? false;
};
export const getUserLevel = (user) => {
    return isAdmin(user) ? UserRole.Admin : UserRole.Client;
};
