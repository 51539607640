import { ICollectionDraft } from '@creatorhub/shared';
import Select, { Props as ReactSelectProps } from 'react-select';

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#322142',
    border: '1px solid #24182f',
    color: '#F5F3F7',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    backgroundColor: '#322142',
    border: 0,
    color: '#F5F3F7',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#322142',
    border: 0,
    color: '#F5F3F7',
  }),
  menu: (provided: any) => {
    return {
      ...provided,
      border: '1px solid #24182f',
      backgroundColor: '#322142',
      color: '#F5F3F7',
      marginTop: '-3px',
    };
  },
  menuList: (provided: any) => {
    return {
      ...provided,
      '-ms-overflow-style': 'none',
      scrollbarWidth: '0.5rem',
      scrollBarColor: '#24182f #08050a',
      '&::-webkit-scrollbar': {
        width: '0.5rem',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#08050a',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#24182f',
      },
    };
  },
  option: (provided: any) => {
    return {
      ...provided,
      padding: '5px',
      backgroundColor: '#322142',
      color: '#F5F3F7',
      borderBottom: '1px solid #24182f',
      '&:hover': {
        backgroundColor: '#24182f',
      },
    };
  },
};

const CustomSingleValue = (props: any) => {
  const {
    hasValue,
    data: { label },
    getStyles,
  } = props;
  return (
    <div
      style={{
        ...getStyles('singleValue', props),
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
      }}
    >
      {hasValue && (
        <>
          {label?.assets?.profileImage != null && (
            <img
              src={label.assets?.profileImage}
              height="32px"
              width="32px"
              className="rounded-full"
            />
          )}
          <div>{label?.symbol}</div>
        </>
      )}
    </div>
  );
};

const CustomOption = ({
  innerProps,
  ...restProps
}: {
  innerProps: any;
  getStyles: (key: string, props: any) => Record<string, any>;
  isDisabled: boolean;
  data: {
    label: ICollectionDraft;
    value: string;
    disabledMessage?: string;
  };
}) => {
  const styles = restProps.getStyles('option', restProps);

  return (
    <div
      {...innerProps}
      onClick={() => {
        if (restProps.isDisabled) {
          return;
        }
        innerProps.onClick();
      }}
      style={{
        ...styles,
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: '15px',
        ...(restProps.isDisabled
          ? {
              opacity: 0.5,
            }
          : {}),
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        {restProps.data.label.assets?.profileImage != null && (
          <img
            src={restProps.data.label.assets?.profileImage}
            height="32px"
            width="32px"
            className="rounded-full"
          />
        )}
        <div>{restProps.data.label?.symbol}</div>
        {restProps.isDisabled && restProps.data.disabledMessage && (
          <span className="text-warning">{restProps.data.disabledMessage}</span>
        )}
      </div>
    </div>
  );
};

type CollectionSelectionOption = {
  label: ICollectionDraft;
  value: string;
  disabledMessage?: string;
};

type CollectionSelectionProps = {
  /* NOTE: currently does not support isMulti select and grouped options only */
  selectedValue?: string;
};

export function CollectionSelection(
  props: CollectionSelectionProps &
    ReactSelectProps<CollectionSelectionOption, false>,
) {
  const { selectedValue, value: reactSelectValue, ...selectProps } = props;
  let value = reactSelectValue;
  if (typeof selectedValue !== 'undefined' && Boolean(props.isMulti) !== true) {
    value =
      (props.options as CollectionSelectionOption[])?.find(
        opts => opts.value === selectedValue,
      ) ?? reactSelectValue;
  }
  return (
    <Select
      {...selectProps}
      value={value}
      components={{
        // eslint-disable-next-line
        // @ts-ignore
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      styles={customStyles}
    />
  );
}
