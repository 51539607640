import { getEnv } from '@creatorhub/shared/services';
import { datadogRum } from '@datadog/browser-rum';
if (getEnv() !== 'local') {
    datadogRum.init({
        applicationId: '4aa211b4-e7cb-4375-afd0-45081a6c9a61',
        clientToken: 'pub0e35772c7b5277b5cc8610618d2d2662',
        site: 'datadoghq.com',
        service: 'creator-hub',
        env: getEnv(),
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.startSessionReplayRecording();
}
